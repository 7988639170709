@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
	font-family: "Jost", sans-serif !important;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	background: #ffffff !important;
}

body a {
	color: inherit;
	text-decoration: none;
}

body a:hover {
	color: black;
	text-decoration: none;
}

input:focus,
select:focus,
button:focus {
	outline: none !important;
	box-shadow: none !important;
}

.swatch-element {
	display: inline-block;
	margin-right: 10px;
}

.swatch-element.available .swatchLbl {
	background-color: #4caf50;
	/* Green for available */
	color: white;
	border: 1px solid #4caf50;
	cursor: pointer;
}

.swatch-element.unavailable .swatchLbl {
	background-color: #f44336;
	/* Red for not available */
	color: white;
	border: 1px solid #f44336;
	cursor: not-allowed;
	/* Disable pointer cursor */
	opacity: 0.5;
	/* Make it less visible */
}

.swatch-element input[type="radio"]:disabled+.swatchLbl {
	cursor: not-allowed;
}

.b-primary {
	background-color: #140707 !important;
}

.t-primary {
	color: #140707 !important;
}

.b-secondary {
	background-color: #ebebd3 !important;
}

.t-secondary {
	color: #ebebd3 !important;
}

.button-primary {
	background: #fff;
	font-size: 16px;
	padding: 6px 15px;
	min-height: 45px;
	border-radius: 0;
	color: #140707;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	border: 1px solid #140707;
	cursor: pointer;
	/* Changes cursor to pointer when enabled */

	&:hover {
		border-color: #140707;
		background: #140707;
		color: #fff;
	}

	&:disabled {
		background: #f0f0f0;
		/* Lighter background when disabled */
		color: #a0a0a0;
		/* Grey color for text */
		border: 1px solid #d0d0d0;
		/* Light grey border */
		cursor: not-allowed;
		/* Changes cursor to not-allowed when disabled */
	}
}

.button-secondary {
	background: #ebebd3;
	font-size: 16px;
	padding: 6px 15px;
	min-height: 45px;
	border-radius: 0;
	color: #140707;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	border: none;
	cursor: pointer;
	/* Changes cursor to pointer when enabled */

	&:hover {
		border: none;
		background: #e1e1c5;
		color: #140707;
	}

	&:disabled {
		background: #d9d9d3;
		/* Lighter background when disabled */
		color: #a0a0a0;
		/* Grey color for text */
		border: none;
		/* Maintain no border for secondary */
		cursor: not-allowed;
		/* Changes cursor to not-allowed when disabled */
	}
}


input.form-control {
	border-color: #ebebd3 !important;
}

.form-control-error {
	color: red;
	font-size: 14px;
	margin-top: 5px;
}


@media (max-width: 767px) {
	body.menu-open{
		overflow: hidden;
	}
	.button-primary {
		font-size: 12px;
		padding: 4px 12px;
		min-height: 35px;
	}
}