.login-page {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.login-page .rightBox img {
    margin-bottom: 50px;
    height: 140px;
}

.login-page .leftBox,
.login-page .rightBox {
    height: 100%;
    width: 100%;
}

.login-page .leftBox {
    background: linear-gradient(135deg, #59a6a9 0%, #1dc4c9 100%);
}

.login-page .leftBox .leftBanner {
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 70px 90px 0;
}

.login-page .leftBox .leftBanner h2 {
    color: #fff;
    font-weight: 900;
    font-size: 50px;
}

.login-page .leftBox .leftBanner p {
    color: #fff;
}

.login-page .leftBox .leftBanner img {
    margin-top: auto;
    max-height: calc(100vh - 282px);
    max-width: 100%;
}

.login-page .rightBox {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-page .rightBox .columns {
    min-width: 320px;
}

.form-control {
    background: #f8f8f8;
    border: 1px solid #e8e9eb;
    color: #333333;
    border-radius: 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    padding: 0 15px;
}

.authBtn {
    background: #1dc4c9;
    font-size: 16px;
    padding: 6px 15px;
    min-height: 47px;
    border-radius: 0;
    color: #ffffff;
    border-color: #1dc4c9;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    border: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authBtn:hover,
.authBtn:active {
    border-color: #59a6a9;
    background: #59a6a9;
    color: #fff;
}

.smallText {
    font-size: 12px;
    text-align: center;
}

@media (max-width: 991px) {
    .login-page .leftBox {
        display: none;
    }
}

@media (min-width: 1600px) {
    .login-page .leftBox .leftBanner {
        padding: 100px 150px 0;
    }

    .login-page .leftBox .leftBanner img {
        max-height: 600px;
    }
}

@media (min-width: 992px) {
    .login-page .leftBox,
    .login-page .rightBox {
        width: 50%;
    }
}