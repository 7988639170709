.cart-page img {
	max-height: 100px;
	width: 100%;
	object-fit: cover;
}

.cart-page svg {
	cursor: pointer;
}

.product-wrapper img {
	height: auto;
	max-width: 350px;
}

table {
	margin-bottom: 15px;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
}

.cart {
	display: block;
	width: 100%;
	border: none;
	margin: 0 0 25px;
}

.cart-header {
	display: flex;
	align-items: center;
	text-align: left;
	width: 100%;
	background-color: #fafafa;
}

.cart-header-item {
	font-size: 12px;
	font-weight: 600;
	color: #232323;
	letter-spacing: .05em;
	line-height: 22px;
	padding: 12px 0;
	background-color: transparent;
	text-transform: uppercase;
}
.cart-header-item:not(.cart-header-info) {
	text-align: center;
	width: 144px;
	padding: 0 15px;
}
.cart-header-item.cart-header-info,
.cart-item-block.cart-item-info {
	width: 50%;
}

.cart-header-item.cart-header-info, 
.cart-item-block.cart-item-info {
    width: calc(100% - 600px);
}

.cart-header-item.orders{
	padding: 12px 0;
	text-align: left;
}

.cart-header-item.cart-header-orderInfo, 
.cart-item-block.cart-item-orderId {
    padding-left: 20px !important;
}

.cart-header-item.cart-header-items, 
.cart-item-block.cart-item-items {
    width: calc(100% - 600px) !important;
}


.cart-header-item.cart-header-total {
	width: 93px;
	text-align: right;
	padding-right: 5px;
}



.cart-list,
.cart-item {
	display: block;
}

.cart-item {
	border: 1px solid #e6e6e6;
	margin-top: 10px;
	padding: 25px 0 25px 5px;
}

.cart-item-block {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	position: relative;
}

.cart-item-block,
.cart-item-block.cart-item-price,
.cart-item-block.cart-item-quantity {
	width: 144px;
}

.cart-item-block.cart-item-quantity {
	padding-top: 0;
	text-align: center;
}

.cart-item-wrapper {
	display: flex;
	align-items: center;
}

.cart-item-block-left {
	padding: 0 15px 0 0;
}

.cart-item-image {
	display: block;
	overflow: hidden;
	position: relative;
	min-width: 90px;
	padding-bottom: 100%;
}

.cart-item-image img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}

.cart-item-block-right {
	position: relative;
	align-self: start;
	padding-top: 2px;
}

.cart-item-name {
	display: block;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: .02em;
	line-height: 20px;
	color: #232323;
	margin: 0 0 6px;
}

.link {
	display: inline-block;
	vertical-align: top;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: .02em;
}

.cart-item-option {
	display: inline-block;
	vertical-align: top;
	position: relative;
	padding: 0 28px 0 0;
	width: auto;
}

.cart-item-variant,
.cart-item-vendor {
	display: block;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	color: #868686;
	text-transform: capitalize;
	letter-spacing: .02em;
	margin-top: 0;
}

.small-screen-visible {
	display: none;
}

.cart-item-quantity .btn-quantity {
	width: 32px;
	height: 45px;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	border: 0;
	background: transparent;
}

.cart-item-quantity .btn-quantity.plus {
	right: 0;
	left: auto;
}

.cart-item-quantity .btn-quantity:before {
	content: "";
	position: absolute;
	width: 12px;
	height: 1px;
	left: 10px;
	top: 22px;
	background: #5a5a5a;
}

.cart-item-quantity .btn-quantity.plus:after {
	content: "";
	position: absolute;
	width: 12px;
	height: 1px;
	left: 10px;
	top: 22px;
	background: #5a5a5a;
	transform: rotate(90deg);
}

.cart-item-qty-input {
	display: flex;
	width: 110px;
	border: 1px solid #c7c7c7;
	align-items: center;
	justify-content: center;
	padding: 0 32px;
	height: 45px;
}

.cart-item-block.cart-item-remove {
	padding: 0;
	width: 45px;
	height: 36px;
	text-align: center;
}

.cart-item-block.cart-item-price {
	padding-top: 0;
	text-align: center;
}

.cart-item__price-wrapper .unit-price,
.cart-item__price-wrapper .money {
	display: block;
	font-size: 14px;
	font-weight: 500;
	color: #232323;
	line-height: 22px;
	letter-spacing: .02em;
	padding-left: 14px;
	padding-bottom: 5px;
}

.cart-item-block.cart-item-total {
	text-align: center;
}

.cart-item-value {
	font-size: 14px;
	font-weight: 500;
	color: #232323;
	line-height: 22px;
	letter-spacing: .02em;
}

.cart-item-qty {
    display: inline-block;
    vertical-align: middle;
    width: 110px;
    position: relative;
    padding-bottom: 6px;
}

.cart--totals-title {
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    line-height: 22px;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #232323;
    position: relative;
    margin: 0 0 20px;
	border-bottom: 1px solid #232323;
}
.cart-total-subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.cart-total-label, .cart-total-value {
    font-weight: 600;
    line-height: 22px;
    letter-spacing: .02em;
    color: .02em;
}


@media (max-width: 767px) {
	.cart-item-block.cart-item-remove {
		grid-area: item-remove;
		margin-left: auto;
		margin-right: 0;
		text-align: right;
	}

	.cart-item-qty-input {
		height: 45px;
	}

	.cart-header-item.cart-header-info {
		padding-left: 15px;
		width: 50%;
	}

	.cart-header-item.cart-header-price {
		width: 50%;
		text-align: right;
	}

	.cart-header-item.cart-header-total,
	.cart-item-block.cart-item-total,
	.cart-header-item.cart-header-quantity {
		display: none;
	}

	.cart-item {
		padding: 10px 10px 10px 0;
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-template-areas:
			"item-info item-price"
			"item-info item-quantity"
			"item-info item-remove";
		column-gap: 10px;
	}

	.cart-item-block {
		padding-bottom: 0;
		max-width: 100%;
		width: 100% !important;
	}

	.cart-item-block.cart-item-info {
		grid-area: item-info;
		padding-left: 10px;
	}

	.cart-item-wrapper {
		align-items: flex-start;
	}

	.cart-item-blocks.small-screen-visible {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
	}

	.cart-item-block.cart-item-quantity {
		grid-area: item-quantity;
		margin-left: auto;
		margin-right: 0;
		text-align: right;
	}

	.cart-item-block.cart-item-info,
	.cart-item-block.cart-item-quantity {
		width: 100%;
	}

	.cart-item-block.cart-item-quantity.small-screen-visible,
	.cart-item-block.cart-item-remove.small-screen-visible {
		display: block;
		text-align: left;
	}

	.cart-item-quantity .btn-quantity {
		height: 45px;
	}

	.cart-item-quantity .btn-quantity.plus:after,
	.cart-item-quantity .btn-quantity:before {
		width: 12px;
		top: 21px;
	}

	.cart-item-block.cart-item-price {
		grid-area: item-price;
		align-self: center;
		margin-left: auto;
		margin-right: 0;
		text-align: right;
	}

	.cart-item-block.cart-item-price {
		transform: translateY(1px);
	}

	.cart-item-block.cart-item-quantity:not(.small-screen-visible),
	.cart-item-block.cart-item-remove:not(.small-screen-visible) {
		display: none;
	}
}