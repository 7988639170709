@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 99;

  & .logo {
    width: auto;
    height: 115px;
  }

  & .search-wrapper {
    max-width: 60% !important;
  }

  & a {
    margin-right: 20px;
    text-transform: uppercase;
    color: #140707;
    font-weight: 500;

    &:hover {
      color: #180606;
    }
  }

  & .logout {
    &:hover {
      cursor: pointer;
      color: #0989ff;
      text-decoration: underline;
    }
  }

  .separator {
    position: absolute;
    border-bottom: 1px solid rgba(1, 15, 28, 0.1);
    width: 100%;
    top: 35px;
  }
}

.logo-name {
  // font-family: "Kanit", sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  line-height: 23px;
}

.headerAdditional {
  flex-grow: 1;
}

.headerAdditional .headerBottomArea {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 75px;
}

.headerAdditional .headerTopArea {
  display: flex;
  height: 35px;
  justify-content: flex-end;
  align-items: center;

  a,
  span {
    font-size: 12px;
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .logout {
    cursor: pointer;
  }
}

.products-number {
  color: red;
  margin-left: 5px;
}

.searchBox {
  background: #f8f8f8;
  border: 1px solid rgba(1, 15, 28, 0.1);
  height: 46px;
  padding-left: 24px;
  padding-right: 50px;
  border-radius: 0;
}

.badge {
  background-color: #ff4343;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 0;
  height: 24px;
  position: absolute;
  right: -13px;
  text-align: center;
  top: -12px;
  width: 24px;
}

.hamburgerIcon {
  font-size: 20px;
  display: none;
}

.sidePanelOverlay {
  display: none;
}

.categories-list-container a {
  margin: 0 20px;
}

.categories-list-container a img {
  display: none;
}

.bottomMenu {
  display: none;
}

@media (max-width: 576px) {
  .nav-bar {}



  .icons-wrapper {
    order: 2 !important;
    // margin: 0 auto;
  }

  .search-wrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  header a{
    font-size: 12px;
  }
  .closeSearchModal {
    position: absolute;
    right: -56px;
    top: 0px;
    background: #f8f8f8;
    border: 1px solid #ebebd3;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  header .search-wrapper {
    position: relative;
    max-width: 100% !important;
    margin-left: 12px !important;
    margin-right: 65px !important;
  }

  .headerAdditional {
    display: none;
    flex-grow: 1;
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 1;
  }

  .search-visible.headerAdditional {
    display: block;
  }

  .logo-link {
    margin: 0 auto;
  }

  header .logo {
    height: 75px;
  }

  .hamburgerIcon {
    display: block;
    width: 48px;
  }

  .separator {
    display: none;
  }

  .categories-list-container {
    position: fixed;
    width: 280px;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 999;
    transform: translateX(-100%);
    transition: 300ms transform;
    flex-direction: column;
    justify-content: flex-start !important;
    box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 17%);
  }

  .menu-visible.categories-list-container {
    transform: none;
  }

  .sidePanelOverlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 99;
  }

  .categories-list-container {
    background: #fff !important;
  }

  .categories-list-container a {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 12px 20px;
    border-bottom: 1px solid #e6e6e6;
    font-weight: 400;
    font-size: 12px;
  }


  .categories-list-container a img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

  .bottomMenu {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 20px;
    background: #fff;
    z-index: 1;
    border-top: 1px solid rgba(1, 15, 28, 0.1);
    padding: 10px;
    justify-content: space-around;
    align-items: center;
  }
}