.product-section {
  margin: 20px 0px;
}

.product-grid {
  display: flex;
}

.product-card {
  width: 200px;
  text-align: center;
  border: 1px solid gainsboro;
  border-radius: 6px;
}

.product-card img {
  width: 100%;
  height: auto;
  max-height: 200px;
  border-radius: 6px;
}

.product-card p {
  margin-bottom: 8px;
  white-space: nowrap;
  /* Prevents text from wrapping to the next line */
  overflow: hidden;
  /* Ensures that overflowed content is hidden */
  text-overflow: ellipsis;
  padding: 0px 5px;
}

.product-card .product-card {
  font-size: 14px;
}


@media (max-width: 767px) {
  .product-section>h2 {
    text-align: center;
    display: flex;
    align-items: center;
  }

  .product-section>h2:before,
  .product-section>h2:after {
    content: '';
    flex: 1;
    height: 1px;
    background: #000;
    margin: 0 20px;
  }
}