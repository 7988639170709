.cart-page img {
	max-height: 100px;
}

.cart-page svg {
	cursor: pointer;
}

.custom-stepper {
	background-color: #140707 !important;
}	