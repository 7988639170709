.product-page .image-wrapper {
	min-width: 45%;
	max-width: 45%;
	display: flex;
	justify-content: center;
	position: relative;

	.nav-tabs {
		margin-right: 10px;
	}

	.nav-tabs .nav-link {
		height: 100px;
		position: relative;
		width: 78px;
		border: 0;
		margin: 0;
		padding: 5px;
		border-radius: 0;

		&:focus,
		&.active {
			box-shadow: 0 0 0 2px #000 !important;
		}

		&:not(:last-child) {
			margin-bottom: 5px;
		}
	}
}

.product-page .image-wrapper .mainImage {
	flex: 1;
}

.product-page .image-wrapper img {
	max-width: 100%;
	max-height: 720px;
	width: 100%;
	object-fit: cover;
}

.product-details {
	flex: 1;
}

.product-desc {
	border-bottom: 1px dotted #a0a0a0;
	padding-bottom: 10px;
	margin-bottom: 10px;

	p {}
}

.product-form__item {
	margin-bottom: 0;
	padding-bottom: 0;
	padding-top: 0;
}

.product-form__item {
	-webkit-flex: 1 1 200px;
	-moz-flex: 1 1 200px;
	-ms-flex: 1 1 200px;
	flex: 1 1 200px;
	margin-bottom: 10px;
	padding: 5px;
}

.product-form .swatch {
	margin-bottom: 10px;
}

.product-form .swatch label {
	display: block;
	text-transform: uppercase;
	font-weight: 400;
	margin: 0 0 5px;
}

.product-form .swatch label .slVariant {
	font-weight: 700;
}

.product-form .swatch .swatch-element {
	display: inline-block;
	margin-right: 8px;
	cursor: pointer;
}

.product-form .swatch .swatchInput+.swatchLbl.color.medium {
	width: 40px;
	height: 40px;
}

.product-form .swatch .swatchInput:checked+.swatchLbl {
	// border: 2px solid #111111;
	box-shadow: 0 0 0 2px #000;
}

.product-form .swatch .swatchInput+.swatchLbl.color {
	position: relative;
	width: 30px;
	padding: 0;
	height: 30px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: 100% auto;
	cursor: pointer;
}

.product-form .swatch .swatchInput+.swatchLbl.large {
	width: 50px;
	height: 50px;
}

.product-form .swatch .swatchInput+.swatchLbl.large:not(.color) {
	line-height: 36px;
}

.product-form .swatch .swatchInput+.swatchLbl {
	position: relative;
	color: #333;
	font-size: 12px;
	// font-weight: 400;
	font-weight: 500;
	line-height: 30px;
	text-transform: capitalize;
	display: inline-block;
	margin: 0;
	min-width: 35px;
	height: 35px;
	overflow: hidden;
	text-align: center;
	background-color: #f9f9f9;
	padding: 0 10px;
	border: 2px solid #ffffff;
	box-shadow: 0 0 0 1px #ddd;
	border-radius: 0;
	cursor: pointer;
}

.product-form .swatch .swatchInput {
	display: none;

}

.product-form .swatch input[type="radio"]+label:after {
	content: '';
	border-style: solid;
	border-width: 0 0 2px 2px;
	border-color: transparent transparent #000000 #000000;
	width: 12px;
	height: 7px;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	z-index: 2;
	margin: -5px auto auto;
	opacity: 0;
	-webkit-transform: scale(1) rotate(-45deg);
	transform: scale(1) rotate(-45deg);
	-webkit-transition: transform 0.3s linear, opacity 0.3s linear;
	transition: transform 0.3s linear, opacity 0.3s linear;
}

.product-form .swatch input[type="radio"]:checked+label:after {
	opacity: 0;
	-webkit-transform: scale(1) rotate(-45deg);
	transform: scale(1) rotate(-45deg);
	color: #6d6d6d;
}

.product-form .swatch input[type="radio"]:checked+label:before {
	content: '';
	// background: rgba(255, 255, 255, 0.5);
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

.product-form .swatch label a {
	font-size: 12px;
	margin-left: 90px;
	font-weight: 500;
	text-transform: none;
}

.product-form .swatch label a .icon {
	font-size: 17px;
}

.bg-color-red {
	background: conic-gradient(#ff4343 0deg 360deg);
}

.bg-color-blue {
	background: conic-gradient(#6babf7 0deg 360deg);
}

.bg-color-green {
	background: conic-gradient(#006400 0deg 360deg);
}

.bg-color-gray {
	background: conic-gradient(#808080 0deg 360deg);
}

.bg-color-orange {
	background: conic-gradient(#f47249 0deg 360deg);
}

.bg-color-black {
	background: conic-gradient(#000 0deg 360deg);
}

.product-action {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0 15px;
	padding: 0 5px;
}

.product-form__item--quantity {
	width: 90px;
	padding-right: 10px;
}

.wrapQtyBtn {
	float: left;
}

.qtyField {
	position: relative;
	max-width: 82px;
	border: 1px solid #dcdcdc;
}

.qtyField .qty {
	font-size: 16px;
	padding: 5px 30px 5px 6px;
	width: 80px;
	text-align: center;
	height: 45px;
	margin: 0;
	border: 0 none;
}

.qtyField button {
	background-color: #f5f5f5;
	color: #000000;
	border-left: 1px solid #dcdcdc;
	position: absolute;
	top: 0;
	right: 0;
	width: 25px;
	height: 50%;
	text-align: center;
	text-decoration: none;
	border-right: 0;
	border-top: 0;
	border-bottom: 0;
}

.qtyField button .an {
	font-size: 15px;
	line-height: 24px;
}

.qtyField button.minus {
	top: 50%;
	border-top: 1px solid #dcdcdc;
}

.product-form__cart-submit {
	background: #1dc4c9;
	font-size: 16px;
	width: 300px;
	padding: 6px 15px;
	min-height: 47px;
	border-radius: 0;
	color: #ffffff;
	border-color: #1dc4c9;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	border: 0;

	&:hover {
		border-color: #59a6a9;
		background: #59a6a9;
		color: #fff;
	}
}

.infolinks {
	border-bottom: 1px dotted #a0a0a0;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.infolinks .wishlist {
	background: none !important;
	color: #000;
	width: auto;
	font-size: 16px;
	text-align: left;
	line-height: inherit;
	height: auto;
	text-decoration: none;
	margin-bottom: 0;
	margin-right: 15px;
	display: inline-block;
}

.infolinks .wishlist:hover {
	color: #555
}

.infolinks .wishlist i {
	font-size: 14px;
}

.product-additionalinfo {
	color: #555555;
	margin-bottom: 10px;
	position: relative;
	line-height: 20px;
	font-weight: 700;

	span {
		padding-left: 10px;
		font-weight: 400;
		text-transform: capitalize;
	}
}

@media (max-width: 767px) {
	.product-page {
		& .image-wrapper {
			width: 100%;
			height: auto;
			justify-content: start;
			max-width: 100%;
			flex-direction: column-reverse;

			& img {
				width: auto;
				height: auto;
			}

			& .nav-tabs {
				margin-right: 0;

				& .color-group {
					display: flex;
				}
			}
		}

		& .product-info {
			flex-direction: column;
		}

		& .product-details {
			margin-top: 1.5rem;
		}
	}
}

@media (max-width: 400px) {
	.product-page .image-wrapper img {
		width: auto;
		// max-width: 300px;
		height: auto;
	}
}