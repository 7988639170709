.filterSidebar {
	min-width: 252px;
	max-width: 252px;
	padding: 20px 10px;
    flex-grow: 0 !important;
    background: #ffffff;
}

.multi-range-slider{
	box-shadow: none;
	border: 0;
}
.multi-range-slider .caption{
	display: none !important;
}

.multi-range-slider .thumb::before{
	box-shadow: none;
	width: 10px;
	margin: -6px -5px;
	border-radius: 0;
	border: 1px solid rgba(1, 15, 28, 0.1);
}
.multi-range-slider .bar-right,
.multi-range-slider .bar-left{
	box-shadow: none;
	border-radius: 0;
}
.multi-range-slider .bar-inner{
	background-color: #ebebd3;
	/* background-color: #888e8e; */
	border: 0;
	box-shadow: none;
}

.filterSidebar h2{
	display: flex;
    font-size: 18px;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
}

.filterSidebar h2 span{
	margin-left: auto;
	font-size: 14px;
	text-decoration: underline;
    text-transform: capitalize;
	cursor: pointer;
}

.filterSidebar h4 {
	border-bottom: 1px solid #dee2e6;
	padding-bottom: 10px;
	font-size: 18px;
}

.price-inputs .form-group{
	position: relative;
	flex: 1;
}

.price-inputs .form-group:after{
	content: 'USD';
	position: absolute;
    right: 8px;
    top: 7px;
}
.price-inputs .form-group input{
	width: 100%;
	border: 1px solid #dee2e6;
	padding: 0.375rem 37px 0.375rem 0.75rem;
    border-radius: 0.375rem;
    text-align: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.price-inputs span{
	padding: 0 10px;
}

.filterSidebar h4 i {
	font-size: 14px;
}

.form-check{
	padding-bottom: 5px;
}

.filter-categories {
    height: 240px;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    padding-right: 10px;
    scrollbar-width: thin;
}

.sortBtn{
	min-width: 190px;
	text-align: left;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/********** Range Input Styles **********/
/*Range Reset*/
input[type="range"] {
	-webkit-appearance: none;
	 appearance: none;
	 background: transparent;
	 cursor: pointer;
	 /* width: 15rem; */
 }
 
 /* Removes default focus */
 input[type="range"]:focus {
   outline: none;
 }
 
 /***** Chrome, Safari, Opera and Edge Chromium styles *****/
 /* slider track */
 input[type="range"]::-webkit-slider-runnable-track {
	background-color: #dee2e6;
	/* border-radius: 0.5rem; */
	height: 0.5rem;  
 }
 
 /* slider thumb */
 input[type="range"]::-webkit-slider-thumb {
   -webkit-appearance: none; /* Override default look */
	appearance: none;
	margin-top: -8px; /* Centers thumb on the track */
 
	/*custom styles*/
	background-color: #140707;
	height: 1.5rem;
	width: 0.5rem;
 }
 
 
 
 /******** Firefox styles ********/
 /* slider track */
 input[type="range"]::-moz-range-track {
	background-color: #dee2e6;
	/* border-radius: 0.5rem; */
	height: 0.5rem;
 }
 
 /* slider thumb */
 input[type="range"]::-moz-range-thumb {
	border: none; /*Removes extra border that FF applies*/
	border-radius: 0; /*Removes default border-radius that FF applies*/
 
	/*custom styles*/
	background-color: #ebebd3;
	height: 2rem;
	width: 1rem;
 }
 
 input[type="range"]:focus::-moz-range-thumb {
   border: 1px solid #dee2e6;
   outline: 3px solid #dee2e6;
   outline-offset: 0.125rem; 
 }
 .filterBtn-mbl{
	display: none;
}
 
@media (min-width: 1400px) {
	.row-cols-xxl-5>* {
		flex: 0 0 20%;
		max-width: 20%;
	}
}

@media (max-width: 767px) {
	.filterSidebar{
		position: fixed;
		min-width: 280px;
		max-width: 280px;
		left: 0;
		top: 0;
		height: 100%;
		z-index: 999;
		transform: translateX(-100%);
		background: #fff;
		transition: 300ms transform;
		overflow-y: auto;
		box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 17%);
	}

	.filterSidebar.filterSidebarIsOpen{
		transform: none;
	}
	.filterBtn-mbl{
		display: block;
		font-size: 16px;
	}

	.filterBtn-mbl i{
		margin-right: 5px;
	}
	.products-container {
		flex-wrap: wrap;
	}
}

 .product-page .image-wrapper .nav-tabs .nav-link img {
	   max-height: 100%;
	 }