#footer {
  margin-top: 36px;
}
.site-footer {
  background: #140707;
  /* background-color: #5cd5eb; */
  border-top: 1px solid #e8e8e8;
  padding: 0;
}
.footer-top {
  line-height: 22px;
  padding-top: 20px;
  padding-bottom: 25px;
  color: #232323;
}
.footer-top,
.footer-links a,
.footer-top p,
.footer-bottom {
  color: #ffffff;
}

.footer-links a:hover{
  color: #EBEBD3;
}

.footer-top h4,
.footer-top .h4 {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 9px;
}
.footer-links   {
 
    /* border-bottom: 1px solid rgb(232 232 232 / 20%); */
 
}
.footer-links .h4 {
  position: relative;
}
.footer-top ul li {
  line-height: 26px;
}

.addressFooter li {
  position: relative;
  padding-bottom: 8px;
}
.addressFooter .icon {
  color: #ffffff;
  float: left;
  font-size: 16px;
  margin-top: 6px;
}
.addressFooter li p {
  padding-left: 22px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}
.site-footer ul {
  list-style: none;
  padding: 0;
}
.site-footer ul.links {
  display: flex;
    justify-content: center;
    gap: 40px;
}
.footer-links ul,
.contact-box ul {
  margin-bottom: 0;

}
.footer-top ul li {
  line-height: 26px;
}

.footer-middle {
  border-top: 1px solid #EBEBD3;
  color: #845b5b;
  padding: 15px 0;
}
.footer-middle .footer-social h3 {
  padding-right: 20px;
  color: #ffffff;
  line-height: 15px;
  vertical-align: middle;
  text-transform: uppercase;
  margin: 0;
}
.list--inline {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.site-footer__social-icons {
  margin: 0 -8px;
}
.list--inline li {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.site-footer__social-icons li {
  padding: 0 8px;
  font-size: 0px;
}
.social-icons .icon {
  color: #ffffff;
  font-size: 16px;
}
.site-footer__social-icons .icon {
  font-size: 21px;

}
.payment-icons li {
  padding: 0 2px;
  font-size: 0px;
  color: #fff;
}
.payment-icons .icon {
  font-size: 32px;
}

.footer-bottom {
  padding: 20px 0;
  text-transform: uppercase;
  border-top: 1px solid #EBEBD3;
  color: #232323;
  letter-spacing: 1px;
}
.copyright {
  color: #fff;
  font-size: 11px;
}
/* Dropdown menu styling */
.help-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  color: #000;
  list-style: none;
  padding: 10px; 
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  left: 1200px;
}
 

.help-dropdown .dropdown-menu a {
  color: #000;
  text-decoration: none;
}

.help-dropdown .dropdown-menu a:hover {
  color: #638ac4 ;
}
.help-dropdown:hover .dropdown-menu {
  display: block;
}

@media (max-width: 575px) {
  .site-footer ul.links{
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }  
}

@media (max-width: 767px)  {
  .site-footer{
    padding-bottom: 50px;
  }
}