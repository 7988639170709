.loader-wrapper {
	opacity: 1;
	transition: opacity .3s;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
}

.loader-wrapper .loader {
	height: 80px;
	width: 80px;
}
