.banner {
  height: auto;
  position: relative;
}

.banner .image-wrapper {
  width: 100%;
}

.banner .image-wrapper img {
  width: 100%;
  max-height: 100%;
}

.banner .image-wrapper .banner-elements {
  position: absolute;
  left: 8%;
  bottom: 60px;
  color: white !important;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.43461134453781514) 0%, rgba(0, 0, 0, 0.27494747899159666) 32%, rgba(0, 0, 0, 0.04245448179271705) 69%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 1) 100%);

}

.banner .image-wrapper .banner-elements h2 {
  text-align: center;
  width: 250px;
}

.banner .image-wrapper .banner-elements button {

  align-self: center;
}

.banner .image-wrapper .banner-elements button {
  opacity: 0.8;
}

@media (max-width: 576px) {
  .banner .image-wrapper .banner-elements h2 {
    margin: 10px !important;
    font-size: 1.5rem;
  }

  .banner .image-wrapper .banner-elements button {
    padding: 0.2rem !important;
  }
  .banner .image-wrapper .banner-elements{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width:768px) {
  .banner .image-wrapper .banner-elements {
    width: 600px;
  }
}