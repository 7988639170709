.home-category-image {
	img {
		max-height: 600px;
		object-fit: cover;
	}

	&:hover {
		opacity: 0.85;
	}
}

.home-category {
	a {
		position: relative;
		padding-top: 100%;
		display: block;
		box-shadow: 0px 0px 1px #999999;

		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(to top, rgb(0 0 0 / 75%), transparent);
			top: 0;
			height: 100%;
			width: 100%;
		}

		&:after {
			content: '';
			position: absolute;
		}
	}

	h2 {
		position: absolute;
		bottom: 0;
		left: 0;
		font-size: 24px;
		color: #fff;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		width: 100%;
		padding: 0 10px 5px;

		@media (max-width: 767px) {
			font-size: 14px;
		}
	}

	.viewDetailsBtn {
		margin-left: auto;
		background: transparent;
		border: 1px solid goldenrod;
		color: #EBEBD3;
		font-size: 20px;
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}