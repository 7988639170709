.add-to-fav {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 20;
	fill: #000000;
    opacity: 0.2;	
}

.add-to-fav svg{
	width: 32px;
    height: 32px;
    background: #fff;
	border-radius: 50%;
    padding: 5px;
}

.add-to-fav:hover{
	opacity: 0.8;
}

.is-red {	
	opacity: 1;
}

.is-red svg{
	background: #ff4343;
}

.is-red path{
	stroke: #fff;
}
