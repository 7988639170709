/* Next & previous buttons */
.prev,
.next {
    line-height: normal;
    font-size: 24px;
    line-height: 50px;
    padding: 0;
    border: 0;
    position: absolute;
    z-index: 4;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background: #140707;
    -ms-transition: all ease-out 0.2s;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;
    border-radius: 50px !important;
}

/* Position the "next button" to the right */
.prev {
    left: 30px;
}

.next {
    right: 30px;
}

/* The dots/bullets/indicators */
.dot {
    cursor: pointer;
    height: 8px;
    width: 15px;
    margin: 0px 4px;
    border-radius: 4px;
    background-color: #ffffff;
    display: inline-block;
    transition: 300ms all;
}

.dots {
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    z-index: 10;
    background: rgb(237 237 227 / 65%);
    padding: 8px 12px;
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: opacity .3s;
    display: flex;
}

.active {
    background-color: #140707;
    width: 30px;
}

.dot:hover {
    background-color: #140707;
}

@media (max-width: 767px) {

    .prev,
    .next,
    .text {
        font-size: 12px;
    }

    .dots {
        margin: 4px !important;
    }

    .dot {
        height: 10px;
        width: 10px;
    }

    .prev,
    .next {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.next:hover {
    color: beige !important;
}

.prev:hover {
    color: beige !important;
}