.layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.layout > div {
	flex-grow: 1;
}

@media (max-width: 991px) {
    .container-sm, .container {
        max-width: 100%;
    }
}