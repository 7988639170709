.user-icon {
    cursor: pointer;
  }
  /* Apply styles to the dropdown container */
  .dropdown {
    position: relative;
  }
  
  /* Hide dropdown-menu by default */
  .dropdown-menu {
    display: none; /* Hidden by default */
    position: absolute;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-top: 1px;
    border-radius: 4px;
    z-index: 10;
    right: 0;
  }
  
  /* Show dropdown-menu when hovering over user-info */
  .dropdown:hover .dropdown-menu {
    display: block; /* Show dropdown on hover */
  }
  
  /* Optional: Additional hover effects */
  .user-info {
    color: #333; /* Default text color */
  }
  .user-info:hover {
    /* color: #007bff;   */
  }
  
  /* Optional: Style the logout link */
  .logout {
    color: #140707 !important; /* Default color */
    font-size: 14px;
    display: block;
  }
  .logout :hover{
    color: #140707 !important; /* Default color */
    
  }