.product-item img {
	height: auto;
	width: 100%;
	max-height: 310px;
	object-fit: cover;
	@media (max-width: 767px) {
		max-height: 170px;
	}
}

.image-container {
	width: 100%;
	background: radial-gradient(circle, #ffffff 0%,  100%);
	min-height: 310px;
	@media (max-width: 767px) {
		min-height: 170px;
	}

	&:hover {
		opacity: 0.85;
	}
}

.product-item {
	position: relative;
	box-shadow: 0px 0px 5px 1px #afafaffa;
	border-radius: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
    display: flex;
    flex-direction: column;

	h3 {
		font-size: 14px;
		font-weight: 400;
	}
	h2{
		font-size: 18px;
		font-weight: 600;
	}
	&:hover{
		.add-to-fav{
			opacity: 0.8;
		}
	}
}

.addToCartBtn{
	font-size: 10px;
    font-weight: 600;
}